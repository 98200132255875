import React from 'react'
import s from './Loader.module.scss'
import clsx from 'clsx'
import {accentColor} from '../../../constants'

const Loader = ({mini, color = accentColor, className = '', style = {}, serverLoading = false}) => {
    return (
        <div style={style} className={clsx(s.loader, mini ? s.mini : '', className)}>
            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="51px" height="51px"
                 viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                <circle cx="50" cy="50" fill="none" stroke={color} strokeWidth="9" r="34"
                        strokeDasharray="160.22122533307947 55.40707511102649">
                    <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s"
                                      values="0 50 50;360 50 50" keyTimes="0;1"/>
                </circle>
            </svg>
            {!!serverLoading && <p>Подождите, приложение запускается</p>}
        </div>
    )
}

export default Loader
