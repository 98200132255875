export const OPEN_SETTINGS = "OPEN_SETTINGS";
export const SET_ERROR_TEXT = "SET_ERROR_TEXT";
export const SET_USER = "SET_USER";
export const SET_ALL_LINES = "SET_ALL_LINES";
export const SET_LOADING = "SET_LOADING";
export const SET_DISPLAY_MODE = "SET_DISPLAY_MODE";
export const SET_SOCKET_ERROR_TEXT = "SET_SOCKET_ERROR_TEXT";
export const IS_AGGREGATION_INCLUDED = "IS_AGGREGATION_INCLUDED";
export const SET_IS_NEW_RELEASE = "SET_IS_NEW_RELEASE ";
export const SET_SHOULD_RETRY = "SET_SHOULD_RETRY";
export const SET_REALEASE_VERSION = "SET_REALEASE_VERSION";
export const RELEASE_INSTALLATION_STATUS = "RELEASE_INSTALLATION_STATUS";
export const RELEASE_UPDATE_ERROR = "RELEASE_UPDATE_ERROR";
export const SET_INSTALLED_RELEASE_VERSION = "INSTALLED-RELEASE-VERSION";
export const SET_INSTALLED_RELEASE_VERSION_INFO =
  "SET_INSTALLED_RELEASE_VERSION_INFO";
export const SET_IS_MANUAL_MODE = "SET_IS_MANUAL_MODE";
export const IS_TEST_MODE_INCLUDED = "IS_TEST_MODE_INCLUDED";
