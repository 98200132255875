import LineAPI from "../../api/LineAPI";
import SettingsAPI from "../../api/SettingsAPI";
import store from "../store";

import { closeWS, runLineWS, ws } from "../../api/ws";
import {
  FULL_ACCESS,
  LOCAL_STORAGE_DATA_NAME,
  productLevels,
} from "../../constants";
import { deleteDataFromLocalStorage, getAccessLevel } from "../../functions";
import {
  setAllLines,
  setAppLoading,
  setErrorText,
  setErrorTextSocket,
} from "../App/appActionCreators";
import {
  setAccessLevelAC,
  setAuthErrorText,
  setIsLoadingAC,
  setIsLoggedAC,
} from "../Auth/authActionCreators";
import {
  changeTypeLine,
  setAllSettings,
} from "../Settings/BaseSettings/baseSettingsActionCreators";
import { SET_EMULATION_MODE } from "../Settings/Line/lineSettingsActions";
import {
  ACTIVATE_ERROR_SIGNAL,
  ACTIVATE_STOP_SIGNAL,
  PAUSE_LINE,
  RESET_LINE,
  RUN_LINE,
  SET_LINE_RUNNED,
  SET_LINE_STATS,
  SET_LOADING_CONTROL,
  SET_PRODUCTION_STATS,
  SET_SELECTED_CONSIGNMENT,
  STOP_LINE,
  UPDATE_PRODUCTS_TABLE,
  UPDATE_PRODUCTS_TABLE_FOR_DROPOUT,
  SET_LINE_PRODUCTION_TIME,
  SET_LINE_ITEM_DROPOUT,
  SET_LINE_ITEM_SUCCESS,
  GET_SCANNED_ITEM_PRODUCTLEVEL,
  GET_PRODUCT_INFO,
  SET_PRELOADED_STATS,
  SET_BOX_FORMED,
  SET_IS_LINE_LOADING,
  SET_TEST_MODE,
} from "./lineActions";
import moment from "moment";

export const updateTableForDO = (DOCode) => ({
  type: UPDATE_PRODUCTS_TABLE_FOR_DROPOUT,
  DOCode,
});

export const runLineAC = () => ({ type: RUN_LINE });
export const stopLineAC = () => ({ type: STOP_LINE });
export const pauseLineAC = () => ({ type: PAUSE_LINE });
export const setLineRunned = (isRunned) => ({
  type: SET_LINE_RUNNED,
  isRunned,
});
export const setLoadingControl = (controlName) => ({
  type: SET_LOADING_CONTROL,
  controlName,
});
export const activateStopSignalAC = (activate) => ({
  type: ACTIVATE_STOP_SIGNAL,
  activate,
});
export const activateErrorSignalAC = (activate) => ({
  type: ACTIVATE_ERROR_SIGNAL,
  activate,
});
export const setSelectedConsignment = (consignment) => ({
  type: SET_SELECTED_CONSIGNMENT,
  consignment,
});
export const setEmulationModeAC = (emulationMode) => ({
  type: SET_EMULATION_MODE,
  emulationMode,
});
export const resetLine = () => ({ type: RESET_LINE });

export const updateProductsTable = (items) => ({
  type: UPDATE_PRODUCTS_TABLE,
  items,
});
export const getLineScannedItemDropoutStatus = (status) => ({
  type: SET_LINE_ITEM_DROPOUT,
  payload: status,
});
export const getLineScannedItemSuccessStatus = (status) => ({
  type: SET_LINE_ITEM_SUCCESS,
  payload: status,
});
export const getScannedItemProductLevel = (level) => ({
  type: GET_SCANNED_ITEM_PRODUCTLEVEL,
  payload: level,
});
export const getProductInfo = (productInfo) => ({
  type: GET_PRODUCT_INFO,
  payload: productInfo,
});
export const setPreloadedStats = (statsFromIncorrectlyStoppedLine) => ({
  type: SET_PRELOADED_STATS,
  payload: statsFromIncorrectlyStoppedLine,
});

export const updateTable = (items) => async (dispatch) => {
  let arrProduct = [...items];

  dispatch(updateProductsTable(arrProduct));
};

export const setLineStats = ({
  total,
  valid,
  notValid,
  error,
  dropout,
  level,
}) => ({
  type: SET_LINE_STATS,
  total,
  valid,
  notValid,
  error,
  dropout,
  level,
});

export const setProductionTime = (payload) => ({
  type: SET_LINE_PRODUCTION_TIME,
  payload,
});

export const setProductionStats = ({
  products,
  boxes,
  pallets,
  totalWeight,
}) => ({
  type: SET_PRODUCTION_STATS,
  products,
  boxes,
  pallets,
  totalWeight,
});

export const changeLine = (lineId) => async (dispatch) => {
  await setAppLoading(true);
  await SettingsAPI?.changeSelectedLine(lineId);
  const selectedLine = await SettingsAPI?.getSelectedLine();

  if (!selectedLine.statusCode) {
    await dispatch(setAllSettings(selectedLine));
    await dispatch(changeTypeLine(selectedLine?.line?.type));
  }
};

export const getLineInfo = () => async (dispatch) => {
  const selectedLine = await SettingsAPI.getSelectedLine();

  await dispatch(setAllSettings(selectedLine));
};

export const setProductInfo = (lineId) => async (dispatch) => {
  const selectedProductInfo = await LineAPI.getProductInfo(lineId);
  await dispatch(getProductInfo(selectedProductInfo));
};

export const getSelectedLineForModal = () => async (dispatch) => {
  dispatch(setIsLineLoading(true));
  const allLines = await SettingsAPI.getLines();
  await dispatch(setAllLines(allLines));
  dispatch(setIsLineLoading(false));
};

export const setIsLineLoading = (isLoading) => {
  return {
    type: SET_IS_LINE_LOADING,
    payload: isLoading,
  };
};

export const getSelectedLine =
  (isAuthorization = false) =>
    async (dispatch) => {
      const localStorageData = localStorage.getItem(LOCAL_STORAGE_DATA_NAME)
        ? JSON.parse(localStorage.getItem(LOCAL_STORAGE_DATA_NAME))
        : null;
      const selectedLine = await SettingsAPI.getSelectedLine();

      if (selectedLine.statusCode) {
        if (selectedLine.statusCode === 400) {
          if (getAccessLevel(localStorageData.roles) === FULL_ACCESS) {
            const allLines = await SettingsAPI.getLines();
            await dispatch(setAllLines(allLines));
          }
        }

        if (selectedLine.statusCode === 403) {
          await dispatch(setAuthErrorText(selectedLine?.message));
          await dispatch(setIsLoggedAC(false));
          deleteDataFromLocalStorage(LOCAL_STORAGE_DATA_NAME);
          await dispatch(setIsLoadingAC(false));
        } else {
          await dispatch(setAuthErrorText(selectedLine?.message));
          await dispatch(setIsLoadingAC(false));
        }
      } else {
        await dispatch(setAllSettings(selectedLine));
        await dispatch(changeTypeLine(selectedLine.line.type));
        await dispatch(setAccessLevelAC(getAccessLevel(localStorageData.roles)));
        await dispatch(
          checkLineStatus(selectedLine.line.status, isAuthorization)
        );

        if (getAccessLevel(localStorageData.roles) === FULL_ACCESS) {
          const allLines = await SettingsAPI.getLines();
          await dispatch(setAllLines(allLines));
        }
        await dispatch(setIsLoadingAC(false));
      }
    };

const tvelveHours = 12 * 60 * 60 * 1000;

const convertToTimeStampNoSeconds = (timeStamp) =>
  Math.floor(Number(timeStamp) / (1000 * 3600)) * 3600 * 1000;

// // //  // // // // // // // RUN LINE STARTS HERE // // // // // // // // // /// // // // // // //
export const runLine =
  ({ consignmentProductCreationAndPackingTimestamp, partNumber }) =>
    async (dispatch, getState) => {
      const partNum = localStorage.getItem('partNumber')

      const timeStampNoSeconds = convertToTimeStampNoSeconds(
        consignmentProductCreationAndPackingTimestamp
      );

      const preloadedstats = store.getState().line.preloadedStats;
      const line = getState().settings.line;
      const { consignmentId, consignmentStatus } = line;
      const selectedProductId = line?.production?.selectedProductId;
      await dispatch(setLoadingControl("RUN"));
      const timeLine = 500;
      let result = [];

      const interval = setInterval(() => {
        if (result.length !== 0) {
          dispatch(updateTable(result));
          result = [];
        }
      }, timeLine);

      if (
        (consignmentStatus && !consignmentId) ||
        (!consignmentStatus && !selectedProductId)
      ) {
        dispatch(setErrorText("Выберите задание или продукт в настройках"));
      } else {
        try {
          const date = new Date(consignmentProductCreationAndPackingTimestamp);
          const res = await LineAPI.openLine(
            line.id,
            timeStampNoSeconds,
            moment(date).format("YYYY-MM-DDTHH:mm:ss"),
            partNumber
          );

          if (res.statusCode === 200) {
            let counter = 0;

            await dispatch(setLoadingControl("RUN"));
            await dispatch(setLoadingControl(""));
            await dispatch(activateStopSignal(false));
            await dispatch(activateErrorSignal(false));

            if (!getState().line.lineRunned) {
              await runLineWS();
              await dispatch(setLineRunned(true));
              await dispatch(setProductInfo(line.id));
              ws.onopen = async function (e) {
                await dispatch(setLoadingControl(""));
              };

              ws.onmessage = async function (event) {
                let data = JSON.parse(event.data);

                switch (data.DataType) {
                  case "ScanInformation":
                    if (data.ProductLevel === 3) {
                      console.log("productLevel ===3", data);
                    }
                    if (data.ProductLevel === 1 && data.Status === "SUCCESS") {
                      counter += 1;
                      data.successfullyFormedBoxNumber = counter;
                    }
                    if (data.Status === "DROPOUT") {
                      await dispatch(updateTableForDO(data.Code));
                      await dispatch(getLineScannedItemDropoutStatus(true));
                    }
                    if (data.Status === "SUCCESS" && data.ProductLevel === 0) {
                      await dispatch(getLineScannedItemSuccessStatus(true));
                    }
                    await dispatch(getScannedItemProductLevel(data.ProductLevel));
                    result.push(data);

                    break;
                  case "ProductionLineStatistics":
                    const {
                      Total,
                      Validly,
                      NotValid,
                      NotRead,
                      ProductLevel,
                      Dropout,
                    } = data;
                    if (ProductLevel === 0) {
                      await dispatch(
                        setLineStats({
                          total: Number(Total),
                          valid: Number(Validly),
                          notValid:
                            Number(NotValid),
                          error: Number(NotRead),
                          dropout: Number(Dropout),
                          level: ProductLevel,
                        })
                      );
                    }
                    if (ProductLevel === 1) {
                      await dispatch(
                        setLineStats({
                          total: Number(Total),
                          valid: Number(Validly),
                          notValid: Number(NotValid),
                          error: Number(NotRead),
                          level: ProductLevel,
                        })
                      );
                    }
                    if (ProductLevel === 2) {
                      await dispatch(
                        setLineStats({
                          total: Total,
                          valid: Validly,
                          notValid: NotValid,
                          error: NotRead,
                          level: ProductLevel,
                        })
                      );
                    }

                    break;
                  case "ProductionLineSummaryStatistics":
                    const {
                      ProductsNumber,
                      BoxesNumber,
                      PalletsNumber,
                      TotalWeight,
                    } = data;
                    await dispatch(
                      setProductionStats({
                        products: ProductsNumber,
                        boxes: BoxesNumber,
                        pallets: PalletsNumber,
                        totalWeight: TotalWeight?.toFixed(3),
                      })
                    );
                    break;

                  case "ProductionLineEvent":
                    const { EventType, EventMessage } = data;
                    if (EventType === "StopLine") {
                      dispatch(setErrorTextSocket(EventMessage));
                      console.log(EventMessage, "EventMessage stopLine");
                    }
                    if (EventType === "DBConnectionError") {
                      dispatch(setErrorTextSocket(EventMessage));
                    }
                    if (EventType === "EmptyMarkingCodesStorage") {
                      console.log(EventMessage, "EventMessage EmptyMarkingCodesStorage");
                      dispatch(setErrorTextSocket("Закончились коды маркировки. Для продолжения операции обратитесь к администратору для заказа новых кодов маркировки."));
                    }
                    break;
                  default:
                    break;
                }
              };
              const pushStack = () => {
                if (result.length !== 0) {
                  dispatch(updateTable(result));
                  result = [];
                }
              };

              ws.onclose = async function (event) {
                if (event.wasClean) {
                  console.log(
                    `[close] Соединение закрыто чисто, код=${event.code} причина=${event.reason}`
                  );
                } else {
                  await LineAPI.inValidStop(line.id);
                  await dispatch(stopLineAC());
                  await dispatch(setLoadingControl(""));
                }
                clearInterval(interval);
              };

              ws.onerror = async function (error) {
                console.log(`[error] ${error.message}`);
              };
            }

            await dispatch(runLineAC());
          }
        } catch (e) {
          console.log(e);
        }
        await dispatch(setLoadingControl(""));
      }
    };

export const stopLine = (pageRefreshed) => async (dispatch, getState) => {
  await dispatch(activateStopSignal(false));
  await dispatch(activateErrorSignal(false));
  let res;

  try {
    if (!pageRefreshed) {
      await dispatch(setLoadingControl("STOP"));
      res = await LineAPI.stopLine(getState().settings.line.id);

      if (res.statusCode === 200) {
        await dispatch(stopLineAC());
        await dispatch(setLineRunned(false));
        await dispatch(resetLine());
        const selectedLine = await SettingsAPI.getSelectedLine();
        localStorage.setItem('partNumber', '')
        if (!selectedLine.statusCode) {
          await dispatch(setAllSettings(selectedLine));
          await dispatch(changeTypeLine(selectedLine?.line?.type));
        }
      } else {
        await dispatch(setErrorText(res?.message));
      }
    }
  } catch (e) {
    console.log(e);
  }
  await dispatch(setLoadingControl(""));
  closeWS();
  return res;
};

export const pauseLine = (pageRefreshed) => async (dispatch, getState) => {
  await dispatch(activateStopSignal(false));
  await dispatch(activateErrorSignal(false));
  
  try {
    if (!pageRefreshed) {
      await dispatch(setLoadingControl("PAUSE"));
      let res = await LineAPI.pauseLine(getState().settings.line.id);

      if (res.statusCode === 200) {
        await dispatch(pauseLineAC());
      } else {
        await dispatch(setErrorText(res.message));
      }
    }
  } catch (e) {
    console.log(e);
  }
  await dispatch(setLoadingControl(""));
};

export const checkLineStatus =
  (status, isDuringAuth = false) =>
    async (dispatch) => {
      if (status === "RUN") {
        dispatch(
          runLine({ consignmentProductCreationAndPackingTimestamp: Date.now() })
        );
      }

      if (status === "PAUSE") {
        dispatch(pauseLine());
        console.log(store.getState().line.preloadedStats.production);
      }

      if (status === "STOP" && !isDuringAuth) {
        dispatch(stopLine());
      }
    };

let activateStopSignalTimer = null;
let activateErrorSignalTimer = null;

export const activateStopSignal = (interval) => async (dispatch, getState) => {
  if (interval) {
    clearTimeout(activateStopSignalTimer);
    await dispatch(activateStopSignalAC(true));
    activateStopSignalTimer = setTimeout(async () => {
      await dispatch(activateStopSignalAC(false));
      clearTimeout(activateStopSignalTimer);
    }, interval);
  } else {
    await dispatch(activateStopSignalAC(false));
  }
};

export const activateErrorSignal = (interval) => async (dispatch, getState) => {
  if (interval) {
    clearTimeout(activateErrorSignalTimer);
    await dispatch(activateErrorSignalAC(true));
    activateErrorSignalTimer = setTimeout(async () => {
      await dispatch(activateErrorSignalAC(false));
      clearTimeout(activateErrorSignalTimer);
    }, interval);
  } else {
    await dispatch(activateErrorSignalAC(false));
  }
};

export const createAggregate = () => async (dispatch, getState) => {
  await dispatch(setAppLoading(true));
  await LineAPI.createAggregate();
  await dispatch(setAppLoading(false));
};

export const getConsignment = (consignmentId) => async (dispatch, getState) => {
  const res = await LineAPI.getConsignmentById(consignmentId);

  if (!res.statusCode) {
    dispatch(setSelectedConsignment(res));
  }
};

export const setEmulationMode =
  (emulationMode) => async (dispatch, getState) => {
    await dispatch(setAppLoading(true));
    let res;

    if (emulationMode) {
      res = await LineAPI.enableEmulationMode(getState().settings.line.id);
    } else {
      res = await LineAPI.disableEmulationMode(getState().settings.line.id);
    }

    if (res.statusCode === 200) {
      emulationMode
        ? await dispatch(setEmulationModeAC(true))
        : await dispatch(setEmulationModeAC(false));
    } else {
      await dispatch(setErrorText(res.message));
    }

    await dispatch(setAppLoading(false));
  };
