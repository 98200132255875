import { displayModes } from "../../constants";
import { returnState } from "../../functions";
import { SET_LOADING } from "../Settings/BaseSettings/baseSettingsActions";
import {
  OPEN_SETTINGS,
  SET_ALL_LINES,
  SET_DISPLAY_MODE,
  SET_ERROR_TEXT,
  SET_SOCKET_ERROR_TEXT,
  SET_USER,
  IS_AGGREGATION_INCLUDED,
  SET_IS_NEW_RELEASE,
  SET_SHOULD_RETRY,
  SET_REALEASE_VERSION,
  RELEASE_INSTALLATION_STATUS,
  RELEASE_UPDATE_ERROR,
  SET_INSTALLED_RELEASE_VERSION,
  SET_INSTALLED_RELEASE_VERSION_INFO,
  SET_IS_MANUAL_MODE,
  IS_TEST_MODE_INCLUDED,
} from "./appActions";

const initialState = {
  isOpenedSettings: false,
  errorText: "",
  errorTextSocket: "",
  user: null,
  loading: false,
  allLines: [],
  selectedDisplayMode: displayModes.ITEMS,
  isNewRelease: false,
  shouldRetry: true,
  releaseVersion: "",
  releaseInstallStatus: "",
  releaseUpdateError: "",
  installedReleaseVersion: "",
  installedRelVerInfo: "",
  redirectToLogin: false,
  isUpdateManualMode: false,
  isTestModeIncluded: false,
};

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        loading: action.isLoading,
      };
    case SET_ALL_LINES:
      return {
        ...state,
        allLines: action.lines,
      };
    case OPEN_SETTINGS:
      return returnState(state, action, "isOpenedSettings");
    case SET_ERROR_TEXT:
      return returnState(state, action, "errorText");
    case SET_USER:
      return {
        ...state,
        user: action.user,
      };

    case SET_SOCKET_ERROR_TEXT:
      return {
        ...state,
        errorTextSocket: action.errorText,
      };
    case SET_DISPLAY_MODE:
      return returnState(state, action, "selectedDisplayMode", "mode");
    case IS_AGGREGATION_INCLUDED:
      return { ...state, isAggregationIncluded: action.payload };
    case SET_IS_NEW_RELEASE:
      return { ...state, isNewRelease: action.payload };
    case SET_SHOULD_RETRY:
      return { ...state, shouldRetry: action.payload };
    case SET_REALEASE_VERSION:
      return { ...state, releaseVersion: action.payload };
    case RELEASE_INSTALLATION_STATUS:
      return { ...state, releaseInstallStatus: action.payload };
    case RELEASE_UPDATE_ERROR:
      return { ...state, releaseUpdateError: action.payload };
    case SET_INSTALLED_RELEASE_VERSION:
      return { ...state, installedReleaseVersion: action.payload };
    case SET_INSTALLED_RELEASE_VERSION_INFO:
      return { ...state, installedRelVerInfo: action.payload };
    case SET_IS_MANUAL_MODE:
      return { ...state, isUpdateManualMode: action.payload };
    case IS_TEST_MODE_INCLUDED:
      return { ...state, isTestModeIncluded: action.payload };
    default:
      return state;
  }
};

export default appReducer;
